import React from "react";
import "./App.css";
import ComingSoon from "./components/ComingSoon";

function App() {
  return (
    <div className="App">
      <ComingSoon title='Coming Summer 2025' message='Under Construction'/>
    </div>
  );
}
export default App;