import React from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";
import CountDown from "../CountDown";

function ComingSoon(props) {
  return (
    <header id="coming-soon">
      <ParticlesBg type="polygon" bg={true} />
      <div className="row banner">
        <div className="banner-text">
          <Fade bottom>
            <h1 className="responsive-headline">{props.title}</h1>
          </Fade>
          <Fade bottom duration={1200}>
            <h3>{props.message}</h3>
          </Fade>
          <CountDown/>
        </div>
      </div>
    </header>
  );
}
export default ComingSoon;